import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { setQueryFunction } from "../../StandardContextUtils";

type AdminListQuery = {
  username: string;
  prefix: string;
  startDate: string;
  endDate: string;
  page: number;
  limit: number;
};

type AdminListServiceContextProps = {
  query: AdminListQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (query?: AdminListQuery) => void;
};

const defaultContext: AdminListServiceContextProps = {
  query: {
    username: "",
    prefix: "",
    startDate: "",
    endDate: "",
    page: 1,
    limit: 10,
  },
  collection: [],
  setQuery: (field: string, value: any) => {},
  onFetch: (query?: AdminListQuery) => {},
};
const getServiceEndpoint = () =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/admin/list`;
export const AdminListServiceContext = createContext(defaultContext);
export const AdminListServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();

  const onFetch = useCallback(
    async (additionalQuery?: AdminListQuery) => {
      try {
        const response = await callToService(getServiceEndpoint(), "POST", {
          ...query,
          ...additionalQuery,
        });
        if (response?.data?.records) setCollection(response?.data?.records);
      } catch (error) {}
    },
    [callToService, query]
  );

  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <AdminListServiceContext.Provider
      value={{
        query,
        collection,
        setQuery,
        onFetch,
      }}>
      {children}
    </AdminListServiceContext.Provider>
  );
};
