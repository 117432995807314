import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { setQueryFunction } from "../../StandardContextUtils";
import { startOfMonth } from "date-fns";

type AgentListQuery = {
  date: string;
};

type AgentTransactionReportServiceContextProps = {
  query: AgentListQuery;
  data: {
    holdBalance: number;
    settleBalance: number;
    voidBalance: number;
    cancelBalance: number;
    depositBalance: number;
    withdrawBalance: number;
    promotionBalance: number;
    promotionRefundBalance: number;
    promotionAffiliateBalance: number;
    transactionCount: number;
  };
  isProcessing: boolean;
  setQuery: (field: string, value: any) => void;
  onFetch: (id: string, date: Date | string | undefined) => void;
};

const defaultContext: AgentTransactionReportServiceContextProps = {
  query: {
    date: "",
  },
  data: {
    holdBalance: 0,
    settleBalance: 0,
    voidBalance: 0,
    cancelBalance: 0,
    depositBalance: 0,
    withdrawBalance: 0,
    promotionBalance: 0,
    promotionRefundBalance: 0,
    promotionAffiliateBalance: 0,
    transactionCount: 0,
  },
  isProcessing: false,
  setQuery: (field: string, value: any) => {},
  onFetch: (id: string, date: Date | string | undefined) => {},
};
const getServiceEndpoint = (id: string) =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent-report/${id}/monthly`;
export const AgentTransactionReportServiceContext =
  createContext(defaultContext);
export const AgentTransactionReportServiceContextProvider: FC<any> = ({
  children,
}) => {
  const [data, setData] = useState(defaultContext.data);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [isProcessing, setIsProcessing] = useState(false);
  const [callToService] = useCallToService();

  const onFetch = useCallback(
    async (id: string, date: Date | string | undefined) => {
      setIsProcessing(true);
      try {
        const response = await callToService(
          getServiceEndpoint(id) +
            `?date=${startOfMonth(
              date ? new Date(date) : new Date()
            ).toISOString()}`,
          "GET"
        );
        if (response?.data && response?.data?.holdBalance)
          setData(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsProcessing(false);
      }
    },
    [callToService]
  );
  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <AgentTransactionReportServiceContext.Provider
      value={{
        query,
        data,
        isProcessing,
        setQuery,
        onFetch,
      }}>
      {children}
    </AgentTransactionReportServiceContext.Provider>
  );
};
