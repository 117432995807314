import { createContext, FC, useCallback, useState } from "react";
import useCallToService from "../../../hooks/useCallToService";
import { setQueryFunction } from "../../StandardContextUtils";

type AgentListQuery = {
  username?: string;
  prefix?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  limit?: number;
  locked?: number;
};

type AgentListServiceContextProps = {
  query: AgentListQuery;
  collection: any[];
  setQuery: (field: string, value: any) => void;
  onFetch: (additionalQuery?: AgentListQuery) => void;
};

const defaultContext: AgentListServiceContextProps = {
  query: {
    username: "",
    prefix: "",
    startDate: "",
    endDate: "",
    page: 1,
    limit: 50,
    locked: undefined,
  },
  collection: [],
  setQuery: (field: string, value: any) => {},
  onFetch: (additionalQuery?: AgentListQuery) => {},
};
const getServiceEndpoint = () =>
  `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/agent/list`;
export const AgentListServiceContext = createContext(defaultContext);
export const AgentListServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState([]);
  const [query, setLocalQuery] = useState(defaultContext.query);
  const [callToService] = useCallToService();

  const onFetch = useCallback(
    async (additionalQuery?: AgentListQuery) => {
      try {
        const response = await callToService(getServiceEndpoint(), "POST", {
          ...query,
          ...additionalQuery,
        });
        if (response.data) setCollection(response.data);
      } catch (error) {}
    },
    [callToService, query]
  );
  const setQuery = setQueryFunction(query, setLocalQuery);

  return (
    <AgentListServiceContext.Provider
      value={{
        query,
        collection,
        setQuery,
        onFetch,
      }}>
      {children}
    </AgentListServiceContext.Provider>
  );
};
