import { FC, useCallback, useContext, useEffect, useRef } from "react";
import CollectionFilter from "../components/collection/CollectionFilter";
import CollectionTable from "../components/collection/CollectionTable";
import NavigationPanel from "../components/NavigationPanel";
import { AgentDeleteServiceContextProvider } from "../contexts/services/agent/AgentDeleteServiceContext";
import {
  AgentListServiceContext,
  AgentListServiceContextProvider,
} from "../contexts/services/agent/AgentListServiceContext";
import SessionVerifier from "../contexts/SessionContext";
import AgentTransactionReportResultHoc from "../components/screens/agentReport/AgentTransactionReportResult";

const AgentReportListScreen: FC<any> = () => {
  const contextAgents = useContext(AgentListServiceContext);
  // const [isUserCreateModalOpen, setUserCreateModalOpen] = useState(false);

  // const [editId, setEditId] = useState("");
  // const [isUserEditModalOpen, setUserEditModalOpen] = useState(false);

  // const [delId, setdelId] = useState("");
  // const [isUserDeleteModalOpen, setUserDeleteModalOpen] = useState(false);

  const fetchCollection = useCallback(() => {
    contextAgents.onFetch({ locked: 0 });
  }, [contextAgents]);

  const shouldFetch = useRef(true);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchCollection();
    }
  });

  return (
    <NavigationPanel>
      <div className="relative pt-6 mt-4">
        <CollectionFilter
          template={[
            {
              name: "ชื่อผู้ใช้",
              field: "username",
              placeholder: "ชื่อผู้ใช้",
              value: contextAgents.query.username,
              type: "text",
            },
            {
              name: "รหัสย่อ",
              field: "prefix",
              placeholder: "รหัสย่อ",
              value: contextAgents.query.prefix,
              type: "text",
            },
            {
              name: "เลือกเดือน",
              field: "startDate",
              placeholder: "วันเริ่มต้นที่ต้องการค้นหา",
              value: contextAgents.query.startDate,
              type: "monthtime",
            },
            // {
            //   name: "ถึงวันที่",
            //   field: "endDate",
            //   placeholder: "วันสิ้นสุดที่ต้องการค้นหา",
            //   value: contextAgents.query.endDate,
            //   type: "datetime",
            // },
          ]}
          onUpdateFilter={(field: any, value: any) =>
            contextAgents.setQuery(field, value)
          }
          onSearch={() => fetchCollection()}
        />
        <CollectionTable
          texts={{
            title: "รายชื่อ Agent",
            description: "รายชื่อเอเย่นท์",
            buttonTextAdd: "เพิ่ม Agent ใหม่",
            buttonTextEdit: "แก้ไข",
          }}
          template={[
            {
              name: "ชื่อผู้ใช้",
              field: "username",
              isLink: true,
              linkPath: "/transaction/{_id}",
              linkFieldReplace: "_id",
            },
            {
              name: "รหัสย่อ",
              field: "prefix",
            },
            {
              name: "รายงาน",
              field: "report",
            },
            {
              name: "สถานะเอเจนท์",
              type: "boolean",
              field: "locked",
            },
            // {
            //   name: "รหัสระบบ",
            //   field: "code",
            // },
            // { name: "ประเภท", field: "agentType" },
            // {
            //   name: "บัญชีถูกระงับ",
            //   type: "boolean",
            //   field: "locked",
            //   isEvent: true,
            //   eventCallback: (data: { _id: string }) => {
            //     setdelId(data._id);
            //     setUserDeleteModalOpen(true);
            //   },
            // },
          ]}
          collection={contextAgents.collection.map((item: any) => ({
            ...item,
            locked: !item.locked,
            report: (
              <AgentTransactionReportResultHoc
                id={item._id}
                date={contextAgents.query.startDate}
              />
            ),
          }))}
          events={
            {
              // onAdd: (data: { codename: string }) => {
              //   setUserCreateModalOpen(true);
              // },
              // onEdit: (data: { _id: string }) => {
              //   setEditId(data._id);
              //   setUserEditModalOpen(true);
              // },
            }
          }
        />
      </div>
    </NavigationPanel>
  );
};

const AgentReportListScreenHoc = (props: any) => {
  return (
    <SessionVerifier>
      <AgentDeleteServiceContextProvider>
        <AgentListServiceContextProvider>
          {/* <AgentTransactionReportServiceContextProvider> */}
          <AgentReportListScreen {...props} />
          {/* </AgentTransactionReportServiceContextProvider> */}
        </AgentListServiceContextProvider>
      </AgentDeleteServiceContextProvider>
    </SessionVerifier>
  );
};
export default AgentReportListScreenHoc;
