import { FC } from "react";
import DatePicker from "react-datepicker";

type CollectionFilterProps = {
  template: {
    name: string;
    placeholder: string;
    field: string;
    type: string;
    value: any;
    valueCollection: any;
    searchID: any;
  }[];
  onUpdateFilter: Function;
  onSearch: Function;
  onUpdateID: Function;
};
/**
 * user: string;
      type: string;
      gameCode: string;
      startDate: string;
      endDate: string;
      page: number;
      limit: number;
 * @param props 
 * @returns 
 */
const CollectionFilter: FC<any> = (props: CollectionFilterProps) => {
  const { template, onUpdateFilter, onSearch, onUpdateID } = props;
  return (
    <div className="px-4 sm:px-6 lg:px-8 mb-4">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">กรองข้อมูล</h1>
        {/* <p className="mt-2 text-sm text-gray-700">
          ท่านสามารถกรองข้อมูลผู้ใช้ได้ตามรายละเอียดด้านล่าง
        </p> */}
      </div>
      <div className="flex flex-row flex-wrap">
        {template.map((item) => (
          <div key={item.field} className="pr-1 mb-3">
            <label className="block text-sm font-medium text-gray-700">
              {item.name}
            </label>
            <div className="mt-1 relative">
              {item.type === "text" && (
                <input
                  type={item.type}
                  name={item.field}
                  id={item.field}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pr-6"
                  placeholder={item.placeholder}
                  value={item.value}
                  onChange={(e) => onUpdateFilter(item.field, e.target.value)}
                />
              )}
              {item.type === "textID" && (
                <input
                  type="text"
                  name={item.field}
                  id={item.field}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pr-6"
                  placeholder={item.placeholder}
                  value={item.value}
                  onChange={(e) => onUpdateID(e.target.value)}
                />
              )}
              {item.type === "datetime" && (
                <DatePicker
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pr-6"
                  placeholderText={item.placeholder}
                  value={item.value}
                  selected={item.value}
                  // value=""
                  onChange={(e) => onUpdateFilter(item.field, e)}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy hh:mm aa"
                  showTimeInput
                />
              )}
              {item.type === "monthtime" && (
                <DatePicker
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pr-6"
                  placeholderText={item.placeholder}
                  // value={item.value}
                  selected={item.value}
                  onChange={(e) => onUpdateFilter(item.field, e)}
                  timeInputLabel="Time:"
                  dateFormat="MM/yyyy aa"
                  // showTimeInput
                />
              )}
              {item.value && (
                <div
                  className="absolute cursor-pointer focus:border-indigo-500 focus:ring-indigo-500"
                  style={{ right: 1, top: 6 }}
                  onClick={(e) => onUpdateFilter(item.field, "")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 ">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mt-4"
        onClick={() => onSearch()}>
        ค้นหา
      </button>
    </div>
  );
};

export default CollectionFilter;
