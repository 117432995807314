import { FC, useCallback, useContext } from "react";
import {
  AgentTransactionReportServiceContext,
  AgentTransactionReportServiceContextProvider,
} from "../../../contexts/services/agent/AgentTransactionReportServiceContext";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

type AgentTransactionReportResultProps = {
  id: string;
  date: Date | string | undefined;
};
const AgentTransactionReportResult: FC<AgentTransactionReportResultProps> = ({
  id,
  date,
}) => {
  // console.log(date);
  const context = useContext(AgentTransactionReportServiceContext);
  const fetchData = useCallback(async () => {
    // console.log(date);
    await context.onFetch(id, date);
  }, [context, id, date]);
  // useEffect(() => {
  //   if (id) {
  //     contextAdapter.setId(id);
  //     contextAdapter.fetch(id);
  //   }
  // }, [id]);

  return (
    <div className="flex flex-row items-start relative gap-4">
      <div className="flex flex-col justify-between h-full">
        <div>ยอดเดิมพัน</div>
        <div>
          {Number(context.data.holdBalance.toFixed(2)).toLocaleString("th-Th")}
        </div>
      </div>
      <div>
        <div>
          ยอดแพ้ชนะ <small>(แพ้ - ชนะ)</small>
        </div>
        <div>
          {Number(
            (
              context.data.holdBalance +
              context.data.settleBalance +
              context.data.cancelBalance +
              context.data.voidBalance
            ).toFixed(2)
          ).toLocaleString("th-Th")}
          {/* {context.data.holdBalance + context.data.settleBalance} */}
        </div>
      </div>
      <div>
        <div>ยอดฝาก</div>
        <div>
          {/* {Number(context.data.holdBalance.toFixed(2)).toLocaleString("th-Th")} */}
          {/* {context.data.depositBalance} */}
          {Number(context.data.depositBalance.toFixed(2)).toLocaleString(
            "th-Th"
          )}
        </div>
      </div>
      <div>
        <div>กำไร</div>
        <div>
          {/* {Number(context.data.holdBalance.toFixed(2)).toLocaleString("th-Th")} */}
          {/* {context.data.depositBalance + context.data.withdrawBalance} */}
          {Number(
            (
              context.data.depositBalance + context.data.withdrawBalance
            ).toFixed(2)
          ).toLocaleString("th-Th")}
        </div>
      </div>
      <div>
        {context.isProcessing ? (
          <>กำลังดึงข้อมูล</>
        ) : (
          <div onClick={() => fetchData()}>
            <ArrowPathIcon width={24} />
          </div>
        )}
      </div>
    </div>
  );
};

const AgentTransactionReportResultHoc = (
  props: AgentTransactionReportResultProps
) => {
  return (
    <AgentTransactionReportServiceContextProvider>
      <AgentTransactionReportResult {...props} />
    </AgentTransactionReportServiceContextProvider>
  );
};
export default AgentTransactionReportResultHoc;
